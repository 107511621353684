import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { FormikProps, withFormik } from 'formik'
import { Button } from '@ubnt/ui-components'
import { History } from 'history'

import {
  AuthError,
  loginMFA,
  selectErrors,
  selectIsLoading,
} from 'features/auth/modules/auth'
import { LoginMFAData, RootState, SSOAPIError } from 'types/types'
import { Description } from 'components/Description'
import authorizedRedirect from 'components/authorizedRedirect'
import styled from 'theme/styled'
import { space } from 'theme'
import { TokenInput } from 'components/TokenInput'
import { selectSendEmailErrors } from 'pages/security/components/Email/modules/sendEmail'

import { AuthForm, ButtonContainer } from '../styles'

interface Props {
  isLoading: boolean
  isAuthenticated: boolean
  apiErrors: AuthError
  sendErrors: SSOAPIError
  handleLogin: typeof loginMFA
  history: History
  handleResend: () => void
  resendIsLoading: boolean
  email?: string
  children: React.ReactNode
}

type FormValues = LoginMFAData

const LoginMFAEmail: React.FC<Props & FormikProps<FormValues>> = ({
  isLoading,
  apiErrors,
  sendErrors,
  errors,
  values,
  setFieldValue,
  children,
  handleResend,
  submitForm,
  email,
}) => {
  const dispatch = useDispatch()
  const [currentError, setCurrentError] = useState<undefined | string>()
  const [resendTimeLeft, setResendTimeLeft] = useState<number>(10)
  const [resendTimer, setResendTimer] = useState<
    ReturnType<typeof setTimeout> | undefined
  >()

  useEffect(() => {
    return () => resendTimer && clearTimeout(resendTimer)
  }, [resendTimer])

  useEffect(() => {
    if (resendTimeLeft > 0) {
      const timer = setTimeout(
        () => setResendTimeLeft(resendTimeLeft - 1),
        1000
      )
      setResendTimer(timer)
    }
  }, [dispatch, resendTimeLeft])

  useEffect(() => {
    if (values.token.length === 6) {
      submitForm()
    }
  }, [submitForm, values.token])

  useEffect(() => {
    if (!currentError && apiErrors?.detail) {
      setCurrentError(apiErrors?.detail)
    }
    if (!currentError && sendErrors?.detail === 'string') {
      setCurrentError(sendErrors?.detail)
    }
  }, [apiErrors, currentError, sendErrors?.detail])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setResendTimeLeft(10)
    handleResend()
  }

  return (
    <AuthForm id="login-mfa-form">
      <StyledDescription>
        <FormattedMessage
          id="LOGIN_MFA_EMAIL_DESCRIPTION"
          values={{ b: () => <b className="intl-message">{email}</b> }}
        />
        <div>
          <Button
            size="medium"
            onClick={handleClick}
            variant="inline"
            disabled={resendTimeLeft > 0}
          >
            {resendTimeLeft > 0 ? (
              <FormattedMessage
                id="SETTINGS_MFA_OPTIONS_RESEND_CODE_TIMER"
                values={{ seconds: resendTimeLeft }}
              />
            ) : (
              <FormattedMessage id="SETTINGS_MFA_OPTIONS_RESEND_CODE" />
            )}
          </Button>
        </div>
      </StyledDescription>

      <TokenInput
        currentError={errors.token || currentError}
        setCurrentError={setCurrentError}
        setFieldValue={setFieldValue}
        disabled={values.token.length === 6}
      />

      <ButtonContainer>
        <Button
          variant="primary"
          disabled={values.token.length < 6}
          loader={isLoading ? 'dots' : undefined}
        >
          <FormattedMessage id="COMMON_ACTION_CONFIRM" />
        </Button>
      </ButtonContainer>

      {children}
    </AuthForm>
  )
}
const enchance = withFormik<Props, FormValues>({
  handleSubmit: (values, { props: { handleLogin, history } }) =>
    handleLogin(values, { history }),
  mapPropsToValues: () => ({
    token: '',
  }),
})
const mapStateToProps = (state: RootState) => ({
  apiErrors: selectErrors(state),
  sendErrors: selectSendEmailErrors(state),
  isLoading: selectIsLoading(state),
})
const mapDispatchToProps = {
  handleLogin: loginMFA,
}

export default authorizedRedirect(
  connect(mapStateToProps, mapDispatchToProps)(enchance(LoginMFAEmail))
)

const StyledDescription = styled(Description)`
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.text3};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 0;
  margin-bottom: ${space['desktop-spacing-base']};
`
