import { fetchRequestWithCookies } from './axios'

const {
  api: { organizations },
} = __CONFIG__

export enum OrganizationRole {
  ORG_OWNER = 'ORG_OWNER',
  ORG_ADMIN = 'ORG_ADMIN',
  SITE_ADMIN = 'SITE_ADMIN',
}

interface Organization {
  orgId: string
  name: string
  role: OrganizationRole
  domain: string
  logoId: string
  logoUrl: string
  inviter: {
    id: string
    email: string
    userName: string
    firstName: string
    lastName: string
  }
  invitationCode: string
}

interface OrganizationApiResponse {
  type: 'collection'
  data: Organization[]
}

export class OrganizationsApi {
  public getAll = async () => {
    const url = new URL(organizations.paths.listOrgs, organizations.base)

    url.searchParams.append('status', 'active')

    const { data } = await fetchRequestWithCookies<OrganizationApiResponse>(
      url.href
    )

    return data.data
  }
}
