import CloudImg from 'assets/images/CloudImg.png'
import PCImg from 'assets/images/PCImg.png'
import UnknownImg from 'assets/images/UnknownImg.png'

import { getProductImage } from '../utils/getProductImage'
import { Category, ProductLine, ZDFormValueType } from './types'

export const productLine: ZDFormValueType[] = [
  {
    id: ProductLine.airMax,
    label: 'airMAX',
    image: getProductImage('791e8a7c-8032-41e9-8d92-94ce59e06062'),
  },
  {
    id: ProductLine.airFiber,
    label: 'airFiber',
    image: getProductImage('c79de5e4-1048-4ede-8e4d-cbc802591fb0'),
  },
  {
    id: ProductLine.ltu,
    label: 'LTU',
    image: getProductImage('4be90270-1242-43c1-97b1-f6095d390f30'),
  },
  {
    id: ProductLine.edgemax,
    label: 'EdgeMAX',
    image: getProductImage('45036a5a-5a2d-4a89-bd9f-6dab86158db2'),
  },
  {
    id: ProductLine.UFiber,
    label: 'UFiber',
    image: getProductImage('121d124b-0b6a-410c-ac41-c8eefdfc78a9'),
  },
  { id: ProductLine.uispCloud, label: 'UISP Cloud', image: CloudImg },
  {
    id: ProductLine.uispConsole,
    label: 'UISP Console',
    image: getProductImage('3af1df03-557b-4243-9aaf-91abbb97aac6'),
  },
  {
    id: ProductLine.uispSelfHosted,
    label: 'Self-Hosted UISP',
    image: PCImg,
  },
  {
    id: ProductLine.uispMobile,
    label: 'UISP Mobile Application',
    image: UnknownImg,
  },
  {
    id: ProductLine.other,
    label: 'Other parts and accessories',
    image: UnknownImg,
  },
]

export const category: ZDFormValueType[] = [
  { id: Category.setup, label: 'Initial setup' },
  { id: Category.updates, label: 'Updates, backups, and migration' },
  { id: Category.performance, label: 'Performance and throughput' },
  { id: Category.hardware, label: 'Hardware issues' },
  { id: Category.other, label: 'Other features and configurations' },
]
