import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ProductName } from 'types/enums'

const getTitle = (productName?: ProductName): React.ReactNode | null => {
  if (productName === ProductName.MOBILITY) {
    return (
      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UMR_HEAD" />
    )
  }
  if (productName === ProductName.HOSTING) {
    return (
      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_CLOUD_CONSOLE_HEAD" />
    )
  }
  if (productName === ProductName.UISP_CLOUD) {
    return (
      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UISP_CLOUD_CONSOLE_HEAD" />
    )
  }
  return null
}

const getSteps = (productName?: ProductName): React.ReactNode[] => {
  if (productName === ProductName.MOBILITY) {
    return [
      <FormattedMessage
        id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UMR_STEP_1"
        values={{
          mobilityLink: <a href="https://mobility.ui.com">mobility.ui.com</a>,
        }}
      />,
      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UMR_STEP_2" />,
      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UMR_STEP_3" />,
      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UMR_STEP_4" />,
      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UMR_STEP_5" />,
    ]
  }
  if (productName === ProductName.HOSTING) {
    return [
      <FormattedMessage
        id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_CLOUD_CONSOLE_STEP_1"
        values={{
          unifiLink: (
            <a href="https://unifi.ui.com" target="_blank" rel="noreferrer">
              unifi.ui.com
            </a>
          ),
        }}
      />,
      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_CLOUD_CONSOLE_STEP_2" />,
      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_CLOUD_CONSOLE_STEP_3" />,
      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_CLOUD_CONSOLE_STEP_4" />,
    ]
  }
  if (productName === ProductName.UISP_CLOUD) {
    return [
      <FormattedMessage
        id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UISP_CLOUD_CONSOLE_STEP_1"
        values={{
          uisplink: (
            <a href="https://uisp.ui.com" target="_blank" rel="noreferrer">
              uisp.ui.com
            </a>
          ),
        }}
      />,
      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UISP_CLOUD_CONSOLE_STEP_2" />,
      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UISP_CLOUD_CONSOLE_STEP_3" />,
      <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_EXT_SUBSCRIPTION_UISP_CLOUD_CONSOLE_STEP_4" />,
    ]
  }
  return []
}

export const useCancelExternalSubscription = (productName?: ProductName) => {
  return { title: getTitle(productName), steps: getSteps(productName) }
}
