import React from 'react'
import { batch } from 'react-redux'
import { IntlShape } from 'react-intl'
import { Dispatch } from 'redux'
import parsePhoneNumber from 'libphonenumber-js'
import { setVisibleModal } from 'modules/modals'
import {
  MFAAuthenticator,
  MFAType,
  MFAAuthenticatorWebAuthn,
  ProviderName,
} from 'modules/types'
import UIVerify from 'assets/images/uiVerify.png'
import GooglePasswordManager from 'assets/images/googlePasswordManager.svg'
import OnePassword from 'assets/images/1Password.svg'
import WindowsHello from 'assets/images/windowsHello.svg'
import SamsungPass from 'assets/images/samsungPass.svg'
import ToothpickPasskeyProvider from 'assets/images/toothpickPasskey.svg'
import Thales from 'assets/images/thales.svg'
import Chrome from 'assets/images/googleChrome.svg'
import IconBackupCodes from 'assets/svgs/backupCodes.svg'
import Yubikey from 'assets/svgs/yubikey.svg'
import { MFAAuthenticatorStatus } from 'enums'
import {
  MFASetupActionTypes,
  setupMFAEmail,
  setupMFATOTP,
} from 'modules/mfaSetup'
import styled from 'theme/styled'
import { EMAIL_REMOVE_MODAL_ID, EMAIL_VERIFY_CODE_MODAL_ID } from '../Email'
import { TOTP_REMOVE_MODAL_ID, TOTP_VERIFY_CODE_MODAL_ID } from '../TOTP'
import { PUSH_REMOVE_MODAL_ID } from '../Push'
import { BACKUP_CODES_PROMPT_MODAL_ID } from '../BackupCodes/BackupCodesPromptModal'
import { initiateMFAPush } from '../Push/modules/initiatePush'
import { SMS_REMOVE_MODAL_ID } from '../SMS/RemoveModal'
import { PASSKEY_REMOVE_MODAL_ID } from '../Passkey/RemoveModal'
import PasskeyIcon from '@ubnt/icons/PasskeyIcon'
import AuthIcon from '@ubnt/icons/AuthIcon'
import MailsCloseIcon from '@ubnt/icons/MailsCloseIcon'
import RepliesIcon from '@ubnt/icons/RepliesIcon'
import LogoAppleIcon from '@ubnt/icons/LogoAppleIcon'
import { motifPalette } from 'theme'
import { Motif } from '@ubnt/ui-components'

const Pending = styled.span`
  color: ${({ theme }) => theme.orange06};
`

const getInfoText = (authenticator: MFAAuthenticator, intl: IntlShape) => {
  const { last_success, status } = authenticator

  if (last_success) {
    return intl.formatMessage(
      {
        id: 'SETTINGS_MFA_METHOD_LAST_DATE_USED',
      },
      {
        date: intl.formatDate(last_success, {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        }),
      }
    )
  }

  if (status === MFAAuthenticatorStatus.PENDING) {
    return (
      <Pending>
        {intl.formatMessage({
          id: 'SETTINGS_MFA_METHOD_STATUS_PENDING',
        })}
      </Pending>
    )
  }

  return intl.formatMessage({
    id: 'SETTINGS_MFA_METHOD_LAST_DATE_NEVER_USED',
  })
}

const getPasskeyIcon = (
  providerName: string,
  motif: Motif
): React.ReactElement => {
  const iconColor =
    motif === 'light'
      ? motifPalette.dark.neutral01
      : motifPalette.light.neutral01

  const icon = {
    [ProviderName.ICLOUD_KEYCHAIN]: (
      <LogoAppleIcon size="large" variant="outline" color={iconColor} />
    ),
    [ProviderName.ICLOUD_KEYCHAIN_MANAGED]: (
      <LogoAppleIcon size="large" color={iconColor} />
    ),
    [ProviderName.GOOGLE_PASSWORD_MANAGER]: <img src={GooglePasswordManager} />,
    [ProviderName.ONE_PASSWORD]: <img src={OnePassword} />,
    [ProviderName.WINDOWS_HELLO]: <img src={WindowsHello} />,
    [ProviderName.SAMSUNG_PASS]: <img src={SamsungPass} />,
    [ProviderName.TOOTHPICK_PASSKEY_PROVIDER]: (
      <img src={ToothpickPasskeyProvider} />
    ),
    [ProviderName.THALES_BIO_IOS]: <img src={Thales} />,
    [ProviderName.THALES_BIO_ANDROID]: <img src={Thales} />,
    [ProviderName.THALES_PIN_IOS]: <img src={Thales} />,
    [ProviderName.THALES_PIN_ANDROID]: <img src={Thales} />,
    [ProviderName.CHROME_ON_MAC]: <img src={Chrome} />,
  }[providerName]

  // There are a lot of yubikeys, and they come up with long names. To avoid a very big list, we match everything that contains the keyword.
  if (!icon && providerName.includes(ProviderName.YUBIKEY)) {
    return <img src={Yubikey} />
  }

  if (!icon) {
    return <PasskeyIcon isActive size="large" />
  }

  return icon
}

const getWebAuthnDescription = (
  authenticator: MFAAuthenticatorWebAuthn,
  intl: IntlShape
) => {
  if (authenticator.status === MFAAuthenticatorStatus.PENDING) {
    return intl.formatMessage({ id: 'SETTINGS_MFA_METHOD_PASSKEY' })
  }
  if (authenticator.name) {
    return authenticator.name
  }

  return authenticator.provider_friendly_name
}

export const prepareData = (
  authenticator: MFAAuthenticator,
  intl: IntlShape,
  dispatch: Dispatch,
  motif: Motif
):
  | {
      iconLink?: string
      icon?: JSX.Element
      typeText: string
      description?: string | null
      action: {
        verify?: {
          text: string
          action: () => void
        }
        remove: {
          action: () => void
        }
        setup?: {
          action: () => void
        }
      }
      info: string | React.ReactNode
      type: MFAType
    }
  | undefined => {
  const info = getInfoText(authenticator, intl)

  switch (authenticator.type) {
    case MFAType.push:
      return {
        iconLink: UIVerify,
        typeText: intl.formatMessage({ id: 'SETTINGS_MFA_METHOD_PUSH' }),
        action: {
          setup: {
            action: () =>
              batch(() => {
                dispatch({
                  type: MFASetupActionTypes.MFA_SETUP_DEACTIVATED_PUSH,
                  payload: { id: authenticator.id },
                })
                dispatch(initiateMFAPush())
              }),
          },
          remove: {
            action: () =>
              batch(() => {
                dispatch({
                  type: MFASetupActionTypes.MFA_REMOVE_PUSH,
                  payload: { id: authenticator.id },
                })
                dispatch(setVisibleModal(PUSH_REMOVE_MODAL_ID))
              }),
          },
        },
        info,
        type: MFAType.push,
      }
    case MFAType.email:
      return {
        icon: <MailsCloseIcon isActive size="large" />,
        typeText: intl.formatMessage({ id: 'SETTINGS_MFA_METHOD_EMAIL' }),
        description: authenticator.email,
        action: {
          verify:
            authenticator.status === MFAAuthenticatorStatus.PENDING
              ? {
                  text: intl.formatMessage({ id: 'COMMON_ACTION_VERIFY' }),
                  action: () =>
                    batch(() => {
                      dispatch(
                        setupMFAEmail(authenticator.id, authenticator.email)
                      )
                      dispatch(setVisibleModal(EMAIL_VERIFY_CODE_MODAL_ID))
                    }),
                }
              : undefined,
          remove: {
            action: () =>
              batch(() => {
                dispatch({
                  type: MFASetupActionTypes.MFA_REMOVE_EMAIL,
                  payload: { id: authenticator.id },
                })
                dispatch(setVisibleModal(EMAIL_REMOVE_MODAL_ID))
              }),
          },
        },
        info,
        type: MFAType.email,
      }
    case MFAType.totp:
      return {
        icon: <AuthIcon isActive size="large" />,
        typeText: intl.formatMessage({ id: 'SETTINGS_MFA_METHOD_TOTP' }),
        action: {
          verify:
            authenticator.status === MFAAuthenticatorStatus.PENDING
              ? {
                  text: intl.formatMessage({ id: 'COMMON_ACTION_VERIFY' }),
                  action: () =>
                    batch(() => {
                      dispatch(setupMFATOTP(authenticator.id))
                      dispatch(setVisibleModal(TOTP_VERIFY_CODE_MODAL_ID))
                    }),
                }
              : undefined,
          remove: {
            action: () =>
              batch(() => {
                dispatch({
                  type: MFASetupActionTypes.MFA_REMOVE_TOTP,
                  payload: { id: authenticator.id },
                })
                dispatch(setVisibleModal(TOTP_REMOVE_MODAL_ID))
              }),
          },
        },
        info,
        type: MFAType.totp,
      }
    case MFAType.sms:
      return {
        icon: <RepliesIcon isActive size="large" />,
        typeText: intl.formatMessage({ id: 'SETTINGS_MFA_METHOD_SMS' }),
        description: parsePhoneNumber(
          authenticator.phone_number
        )?.formatNational(),
        action: {
          remove: {
            action: () =>
              batch(() => {
                dispatch({
                  type: MFASetupActionTypes.MFA_REMOVE_SMS,
                  payload: { id: authenticator.id },
                })
                dispatch(setVisibleModal(SMS_REMOVE_MODAL_ID))
              }),
          },
        },
        info,
        type: MFAType.sms,
      }
    case MFAType.webAuthn:
      return {
        icon: getPasskeyIcon(authenticator.provider_friendly_name, motif),
        typeText: intl.formatMessage({
          id: 'SETTINGS_MFA_METHOD_PASSKEY',
        }),
        description: getWebAuthnDescription(authenticator, intl),
        action: {
          remove: {
            action: () =>
              batch(() => {
                dispatch({
                  type: MFASetupActionTypes.MFA_REMOVE_PASSKEY,
                  payload: { id: authenticator.id },
                })
                dispatch(setVisibleModal(PASSKEY_REMOVE_MODAL_ID))
              }),
          },
        },
        info,
        type: MFAType.webAuthn,
      }
    case MFAType.backupCodes:
      return {
        iconLink: IconBackupCodes,
        typeText: intl.formatMessage({
          id: 'SETTINGS_MFA_METHOD_BACKUP_CODES',
        }),
        description: `${authenticator.codes_left} recovery codes left`,
        action: {
          verify: {
            text: intl.formatMessage({ id: 'COMMON_ACTION_REGENERATE' }),
            action: () =>
              dispatch(setVisibleModal(BACKUP_CODES_PROMPT_MODAL_ID)),
          },
          remove: {
            action: () => null,
          },
        },
        info,
        type: MFAType.backupCodes,
      }
  }
}
