import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Text } from '@ubnt/ui-components/aria'
import { GenericModal } from 'components/generic-modal/GenericModal'
import ModalWrapper, { ModalProps } from 'components/ModalWrapper'
import { useCancelSubscription } from 'pages/subscriptions/hooks/useCancelSubscription'
import { FullSubscription } from 'features/subscriptions/fullSubscriptions/useFullSubscriptions'
import { ProductName } from 'types/enums'
import { useHistory } from 'react-router-dom'

interface Props extends ModalProps {
  fullSubscription?: FullSubscription
  productName?: ProductName
}

const CancelSubscriptionModal: React.FC<Props> = ({
  isOpen,
  onClose,
  fullSubscription,
  productName,
}) => {
  const history = useHistory()
  const data = useCancelSubscription(productName, fullSubscription)

  if (!data) {
    return null
  }

  const onCancel = () => {
    if (data.cancelError) {
      history.push('/supportform?formtype=billing')
    }
    data.cancelFn()
  }

  return (
    <GenericModal
      isOpen={isOpen}
      onRequestClose={onClose}
      title={
        <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION" />
      }
      size="small"
      onAfterClose={() => data.resetCancelErrors()}
      actions={[
        {
          text: <FormattedMessage id="COMMON_ACTION_CANCEL" />,
          onClick: onClose,
        },
        {
          text: data.cancelError ? (
            <FormattedMessage id="SETTINGS_SUBSCRIPTIONS_CONTACT_SUPPORT" />
          ) : (
            <FormattedMessage id="COMMON_ACTION_CONFIRM" />
          ),
          variant: 'primary',
          onClick: onCancel,
          loader: data.isCancelLoading ? 'dots' : undefined,
          type: 'submit',
          disabled: data.isCancelLoading,
        },
      ]}
    >
      {data.cancelError ? (
        <Text color="red-06">{data.cancelError}</Text>
      ) : (
        <Text>
          <FormattedMessage
            id="SETTINGS_SUBSCRIPTIONS_CANCEL_SUBSCRIPTION_MESSAGE"
            values={{ name: fullSubscription?.name }}
          />
        </Text>
      )}
    </GenericModal>
  )
}

export const CANCEL_SUBSCRIPTION_MODAL_ID = 'CANCEL_SUBSCRIPTION_MODAL_ID'

export const WrappedCancelSubscriptionModal = () => (
  <ModalWrapper modalId={CANCEL_SUBSCRIPTION_MODAL_ID}>
    <CancelSubscriptionModal />
  </ModalWrapper>
)
