import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { selectIsDoneAuthenticating } from 'features/auth/modules/auth'
import { QueryKey } from 'store/types'
import { FIVE_MINUTES_IN_MILLISECONDS } from 'store/constants'
import { ChatApi } from 'api/chat'

const chatApi = new ChatApi()

export const useChatScheduleQuery = () => {
  const isDoneAuthenticating = useSelector(selectIsDoneAuthenticating)

  const chatScheduleStatus = useQuery({
    queryKey: [QueryKey.CHAT_SCHEDULE],
    queryFn: chatApi.getSchedule,
    enabled: isDoneAuthenticating,
    staleTime: FIVE_MINUTES_IN_MILLISECONDS,
  })

  return {
    isChatOpen: chatScheduleStatus.data?.data.isChatOpen,
    isAdminFlagOn: chatScheduleStatus.data?.data.isAdminFlagOn,
    isChatScheduleFetched: chatScheduleStatus.isFetched,
    isChatScheduleFetching: chatScheduleStatus.isFetching,
    refetchIsChatOpen: chatScheduleStatus.refetch,
    isError:
      chatScheduleStatus.isError || chatScheduleStatus.data?.status === 0,
  }
}
