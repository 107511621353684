import { country, orderIssue } from './ConfigOptions'
import { FormConfig, OrderIssue } from './types'

export const config: FormConfig = {
  country: {
    fieldId: 360037876773,
    fieldTitle: 'SUPPORT_STORE_FORM_COUNTRY',
    options: country,
    nextSection: [{ section: 'orderIssue' }],
  },
  orderIssue: {
    fieldId: 360037027074,
    fieldTitle: 'SUPPORT_STORE_FORM_ISSUE',
    options: orderIssue,
    nextSection: [
      {
        section: 'orderNumber',
        condition: {
          field: 'orderIssue',
          value: [
            OrderIssue.trackOrder,
            OrderIssue.cancelOrder,
            OrderIssue.other,
          ],
        },
      },
      {
        section: 'info',
        condition: {
          field: 'orderIssue',
          value: [
            OrderIssue.changeOrder,
            OrderIssue.returnOrder,
            OrderIssue.productAvailability,
            OrderIssue.taxExemption,
          ],
        },
      },
    ],
  },
  info: {
    fieldId: 0,
    fieldTitle: '',
    options: null,
    nextSection: null,
  },
  orderNumber: {
    fieldId: 360036980233,
    fieldTitle: 'SUPPORT_STORE_FORM_ORDER_NUMBER',
    options: null,
    nextSection: [{ section: 'description' }],
  },
  description: {
    fieldId: 20012826,
    fieldTitle: 'SUPPORT_STORE_FORM_DESCRIPTION',
    options: null,
    nextSection: null,
  },
  attachments: {
    fieldId: 10138128074775,
    fieldTitle: 'SUPPORT_ATTACHMENT_UPLOAD_RELEVANT',
    options: null,
    nextSection: null,
  },
  rawData: {
    fieldId: 1500011191181,
    fieldTitle: null,
    options: null,
    nextSection: null,
    hide: true,
  },
}
